import axios from "axios";

let isAuthenticated;
const urlsToSkip = ["s3.amazonaws.com"];

export const wrapAxios = (isAuthd, getAccessTokenSilently) => {
  isAuthenticated = isAuthd;

  axios.interceptors.request.use(async (config) => {
    const skipAddingAuth = urlsToSkip.some((url) => config.url.includes(url));
    if (isAuthenticated && !skipAddingAuth) {
      const token = await getAccessTokenSilently();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};
