import { ReactComponent as LoadingSVG } from "../img/loading-logo-anim.svg";

function Loader({ message }) {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "wrap",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        width: 200,
        height: 102,
        padding: 5,
      }}
    >
      <LoadingSVG
        style={{
          display: "flex",
          zIndex: 1,
          height: 100,
        }}
      />
      <h4
        style={{
          fontFamily: "Source Sans Pro",
          display: "block",
          zIndex: 2,
        }}
      >
        Prepping data...
      </h4>
    </div>
  );
}

export default Loader;
