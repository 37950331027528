import React, { Suspense, useEffect, lazy } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MuiTheme from "./utils/MuiTheme";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { wrapAxios } from "./routes/utils/wrapAxios";
import Loader from "./retool/components/Loader";
const AppRoutes = lazy(
  () =>
    new Promise((resolve) =>
      setTimeout(() => resolve(import("./routes/AppRoutes")), 300)
    )
);
const theme = createTheme(MuiTheme);

const queryClient = new QueryClient();

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    const entryUri = localStorage.getItem("entryUrl");
    const returnToUri = entryUri || "/login";
    navigate(appState?.returnTo || returnToUri, { replace: true });
  };

  const WithAxios = ({ children }) => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    useEffect(() => {
      wrapAxios(isAuthenticated, getAccessTokenSilently);
    }, [isAuthenticated, getAccessTokenSilently]);

    return <>{children}</>;
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      <WithAxios>{children}</WithAxios>
    </Auth0Provider>
  );
};

function App({ history }) {
  useEffect(() => {
    if (window.location.pathname !== "/login") {
      localStorage.setItem("entryUrl", window.location.pathname);
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback
            domain="auth.ourkettle.com"
            clientId="CUD9tZLnyPDpxujkHYbi8mNkCppZQtU7"
            audience="https://dev-1o3jlswz.auth0.com/api/v2/"
            scope="openid profile email app_metadata user_metadata"
            redirectUri={window.location.origin + "/login"}
          >
            <Suspense fallback={<Loader />}>
              <AppRoutes history={history} />
            </Suspense>
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
