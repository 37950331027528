import React from "react";
import ReactDOM from "react-dom";

import { Helmet } from "react-helmet";
import "./index.css";
import App from "./App";
const history = {};
ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <link
        href="https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css"
        rel="stylesheet"
      />
    </Helmet>

    <App history={history} />
  </React.StrictMode>,
  document.getElementById("root")
);
