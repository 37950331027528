const muiTheme = {
  palette: {
    primary: {
      main: "#fea973",
      contrastText: "#fff",
    },
    secondary: {
      main: "#e96f73",
      contrastText: "#fff",
    },
    error: {
      main: "#e96f73",
    },
    warning: {
      main: "#ffc107",
    },
    info: {
      main: "#0dcaf0",
    },
    success: {
      main: "#198754",
    },
  },
};

export default muiTheme;
